html,
body,
#root {
	height: 100%;
}

.textOverlay::after {
	content: " ";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 1;
	background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		var(--chakra-colors-gray-900) 0%,
		rgba(0, 0, 0, 0) 70%
	);
}

.textOverlay.active::after {
	opacity: 0;
}

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #363636;
}

*::-webkit-scrollbar {
	width: 4px;
	background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: var(--chakra-colors-brand_gradient-500);
}

.menuContentWrapper::-webkit-scrollbar {
	display: none;
}

embed {
	border-radius: 28px;
	max-width: 400px;
	max-height: 70vh;
	box-shadow: 0px 0px 20px 0px #00000080;
	margin-bottom: 15px;
	box-sizing: border-box;
	width: -webkit-fill-available;
	margin: 0 auto 10px auto;
	display: block;
}

.mediaPreview {
	object-fit: contain;
}

.messageViewer image,
.messageViewer img,
.messageViewer video {
	max-width: 100%;
	height: auto;
	max-height: 400px;
	width: fit-content;
	margin: 0 auto;
}

#animationIFrame::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
}

#animationIFrame iframe {
	z-index: -1;
}
